import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import './footer.css'

const Footer = () => (
  <footer className="dib w-100 pv3 ph3 ph5-m ph6-l mid-gray tc">
    <small>
      Igepa CZ s.r.o., Ke Stadionu 400, 250 70 Odolena Voda, IČO: 48950441, DIČ:
      CZ48950441, jsme distributorem papírů Color Copy pro ČR
    </small>
    <div className="tc mt2">
      <a
        href="tel:739531496"
        title="tel:739531496"
        className="f6 dib ph2 link mid-gray dim"
      >
        tel.: 739 531 496
      </a>
      <a
        href="tel:283971985"
        title="tel:283971985"
        className="f6 dib ph2 link mid-gray dim"
      >
        tel.: 283 971 985
      </a>
      <a
        href="tel:283971986"
        title="tel:283971986"
        className="f6 dib ph2 link mid-gray dim"
      >
        tel.: 283 971 986
      </a>
      <a
        href="mailto:prodej@igepagroup.com"
        title="prodej@igepagroup.com"
        className="f6 dib ph2 link mid-gray dim"
      >
        e-mail: prodej@igepagroup.com
      </a>
    </div>
    <div className="f6 tc mt2">
      <a className="f6 dib ph2 link mid-gray dim" href="/obchodni-podminky">
        Obchodní podmínky
      </a>
      <a
        className="f6 dib ph2 link mid-gray dim"
        href="/ochrana-osobnich-udaju"
      >
        Ochrana osobních údajů
      </a>
    </div>
  </footer>
)

export default Footer
