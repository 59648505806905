import React, { useCallback, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import map from 'lodash/fp/map'

import Footer from '../components/Footer'
import Header from '../components/Header'

import './tachyons/css/tachyons.css'
import './custom.css'
import './layout.css'

export default ({ children }) => {
  const [cart, setCart] = useState({ items: [] })
  const refreshCart = useCallback(() => {
    fetch('/api/cart')
      .then(resp => {
        return resp.json()
      })
      .then(cart => {
        setCart(cart)
      })
  }, [])

  useEffect(() => {
    refreshCart()
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className="pageLayout">
          <Helmet
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            defaultTitle={data.site.siteMetadata.title}
          >
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=UA-146141738-1"
            />
            <script>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-146141738-1');
            `}
            </script>
            <script
              type="text/javascript"
              src="https://cdn.jsdelivr.net/npm/cookie-bar/cookiebar-latest.min.js?always=1&remember=30&privacyPage=http%3A%2F%2Fwww.colorcopy.cz%2Fcookies"
            />
            <link
              rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/cookie-bar/themes/cookiebar.min.css"
            />
          </Helmet>
          <div className="pageContent">
            <Header cart={cart} />
            <div className="db w-100 pa3 ph6-l pv3-l">
              {children && children({ cart, refreshCart })}
            </div>
          </div>
          <Footer />
        </div>
      )}
    />
  )
}
