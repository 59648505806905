import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { FaShoppingCart } from 'react-icons/fa'
import reduce from 'lodash/fp/reduce'

import logo from './logo.png'
import './header.css'

const Header = ({ cart }) => {
  const cartPrice = reduce((price, item) => item.price + price, 0, cart.items)

  return (
    <header className="header ph4-l pv3 tc tl-l">
      <Link className="w-100 w-10-l" to="/">
        <img src={logo} alt="Color Copy" width="100"/>
      </Link>
      <div className="fr-l pv3 v-mid flex justify-around header__menu">
        <StaticQuery
          query={graphql`
            query MenuProducts {
              allProduct {
                edges {
                  node {
                    id
                    key
                    titleColor
                    name
                    finish
                    grammages
                    formats
                    presentations
                    textGraphics
                    photoBooks
                    finishingPostProcessing
                    image {
                      url
                    }
                  }
                }
              }
            }
          `}
          render={data => {
            return (
              <>
                {data.allProduct.edges.map(edge => (
                  <a
                    key={edge.node.key}
                    className="link ph3-l"
                    href={`/${edge.node.key}/`}
                  >
                    {edge.node.name}
                  </a>
                ))}
              </>
            )
          }}
        />
        <a className="link ph2-l" href="/nakupni-kosik/">
          <FaShoppingCart className="f3" />
          {cartPrice > 0 && (
            <div
              className="f6 grow no-underline br-pill pa1 ph2 mb2 white bg-dark-blue"
              style={{ position: 'relative', top: '-2.8rem', right: '-0.2rem' }}
            >{`${cartPrice},-kč`}</div>
          )}
        </a>
      </div>
    </header>
  )
}

export default Header
